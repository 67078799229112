<template>
  <div>
    <custom-breadcrumb
        :pageTitle="$t('Create Catalog')"
        :breadcrumb="[
          {
            to: 'resources',
            text: this.$t('Educational Resources')
          },
          {
            to: 'catalogs',
            text: this.$t('resources.catalogs')
          },
        ]"
    ></custom-breadcrumb>
    <b-row v-if="loading">
      <b-col cols="12"  >
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>
    <template v-else>
      <b-tabs content-class="mt-2">
          <b-tab
            active
            :title="$t('learning_path.information')"
          >
            <b-card>
              
                <b-form>
                  <b-row class="mb-1">
                    <b-col cols="12" v-if="resolveValidation('contents')">
                      <b-alert
                        variant="danger"
                        show
                      >
                        <div class="alert-body">
                          {{ $t('learning_path.contents_missing')}}
                        </div>
                      </b-alert>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- title -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('resources.title')"
                        label-for="v-title"
                      >
                          <b-form-input
                            id="v-title"
                            :state="resolveValidation('title')"
                            v-model="title"
                          />
                          <b-form-invalid-feedback >
                            {{ $t('learning_path.title_missing')}}
                          </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- description -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('resources.description')"
                        label-for="v-description"
                      >
                        <b-form-textarea
                          id="v-description"
                          v-model="description"
                          rows="3"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('resources.lang_info')"
                        label-for="i-language"
                        class="mb-3"
                      >
                      <b-form-radio-group
                          id="i-language"
                          v-model="language"
                          name="i-language"
                          class="demo-inline-spacing"
                        >
                          <b-form-radio v-for="option in langs"
                          :state="resolveValidation('language')"
                          :key="option.id"
                          :value="option">
                            {{ $t('lang.'+option.name) }}
                          </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="resolveValidation('language')">
                        {{ $t('learning_path.language_missing')}}
                      </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- age range -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('resources.age_info')"
                        label-for="i-age_range"
                      >
                        <vue-slider
                          v-model="age_range"
                          :adsorb="true"
                          :data="ages"
                          :marks="true"
                          :included="true"
                          class="mb-2"
                          :min-range="1"
                          direction="ltr"
                          :max-range="7"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  
                </b-form>
            </b-card>
          </b-tab>
          <b-tab :title="$t('learning_path.activities')">
            <div class="ecommerce-application">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h4 class="mb-0">
                    {{$t('learning_path.select_contents')}}
                  </h4>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="3" class="d-lg-block d-none">
                  <left-filter-sidebar 
                    @contents-hierarchy-selected="selectedContentsHierarchy"
                    @search="searchContents"
                    @template-changed="handleTemplateChange"
                    :contentsHierarchy="contents_hierarchy"
                    :loading="loading_contents"
                    :filterOptions="filterOptions"/>
                </b-col>
                <b-col>
                  <contents-selector-container 
                    :loading="loading_contents"
                    :isReport="true"
                    :searchword.sync="searchword"
                    :contentsHierarchy="contents_hierarchy"
                    :filterOptions="filterOptions"
                    :contents="filtered_contents"
                    :route_contents="selected_contents"
                    :sortedContents="sortedContents"
                    :showSearchbar.sync="showSearchbar"
                    @toggle-content-in-list="toggleContentInList"
                    @select-all-content="selectAllContent"
                    @search="searchContents"
                    @unselect-filter="unselectFilter"
                    @contents-hierarchy-selected="selectedContentsHierarchy"/>
                </b-col>
              </b-row>
            </div>
          </b-tab>
      </b-tabs>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="outline-secondary"
          type="button"
          @click="reset"
          class="mr-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.erase')}}
        </b-button>
        <b-button
          variant="primary"
          type="button"
          @click="save"
          class="ml-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('resources.save_new')}}
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import { createCatalog } from '@/api/reports'
import {getCreateLearningPathInitialData, fetchContentSearch } from '@/api/learningpaths'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import {BAlert, BSpinner, BFormInvalidFeedback, BButton, BForm, BFormRadio, BFormRadioGroup, BFormGroup, BCol, BRow, BFormTextarea, BFormInput, BTabs, BTab, BCardText, BCard } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import LeftFilterSidebar from '@/views/learning-paths/components/LeftFilterSidebar.vue'
import ContentsSelectorContainer from '@/views/learning-paths/components/ContentsSelectorContainer.vue'

export default {
  components: {
    LeftFilterSidebar,
    ContentsSelectorContainer,
    VueSlider,
    CustomBreadcrumb,
    BCardText, BCard, BForm, 
    BFormRadio, BFormRadioGroup, BFormGroup, BCol, BRow, BFormTextarea, BFormInput, BTabs,
    BTab,BAlert, BSpinner, BFormInvalidFeedback, BButton
  },
  data(){
    return {
      showSearchbar: true,
      title: "",
      description: "",
      language: "",
      age_range: [7,9],
      selected_contents: [],
      loading: true,
      loading_contents: true,
      ages: [3, 6, 7, 8, 9, 10, 11, 12],
      langs: [],
      searchword:'',
      filterOptions: [],
      contents_hierarchy: [],
      contents: {},
      filtered_contents: {},
      errors:[],
      sortedContents:[]
    }
  },
  mounted(){
    if(this.$store.getters['user/getUserData'].id == 1081 ||this.$store.getters['user/getUserData'].role == 'admin'){
      this.getData()
    } else {
      this.$router.push({ name: 'main-home' });
    }
  },
  computed:{
    },
  methods:{
    reset(){
      this.title = ""
      this.description = ""
      this.language = ""
      this.age_range = [7,9]
      this.selected_contents = []
      this.searchword = ''
      this.errors = []
    },
    resolveValidation(form_input){
      if(form_input == 'contents'){
        return this.errors.includes(form_input)
      }
      return this.errors.includes(form_input) ? false : null
    },
    selectedContentsHierarchy(contents){
      if(contents.length == 0){
        this.sortedContents = []
        this.filtered_contents = this.contents
      } else {
        let contents_found = {}
        contents.map(id => {
          contents_found[id] = this.contents[id]
        })
        this.sortedContents = contents
        this.filtered_contents = contents_found
      }
    },
    async getData(){
      this.loading = true
      let data = {
        'user_id':  this.$store.getters['user/getUserData'].id,
        'token': this.$store.getters['user/getUserData'].sso,
      }
      await this.$http.post(getCreateLearningPathInitialData, data).then( response => {
        // console.log("getCreateLearningPathInitialData", response.data)
        
        if(response.data.status == 200){
          this.langs = response.data.langs

          this.filterOptions = response.data.filterOptions   
          this.contents_hierarchy = response.data.contents_hierarchy   
          this.contents = response.data.contents  
          this.filtered_contents = response.data.contents  

          this.loading_contents = false
          this.loading = false
        } else {
          this.makeToast('danger', this.$t('Error'),this.$t('error_msg'));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    async searchContents(){
      // console.log("buscar contenidos con esta palabra:", this.searchword, "y con los criterios de filter options seleccionados")
      let data = {
        'user_id':  this.$store.getters['user/getUserData'].id,
        'q': this.searchword,
        'token': this.$store.getters['user/getUserData'].sso,
      }
      let filters = this.filterOptions
      // console.log(filters)
      filters.forEach(filter_category => {
        let selected_options = []
        if(filter_category.name=='age'){

          let ages = []
          filter_category.options.map(op => {
              if(op.selected){
                  let ages_arr = op.id.split('-')
                  ages_arr.map(age => {
                      ages.push(parseInt(age))
                  })
              }
          })
          let min = Math.min(...ages)  
          let max = Math.max(...ages)
          
          selected_options = [min, max]

        } else {
          filter_category.options.forEach(opt => {
            if(opt.selected){
              selected_options.push(opt.id)
            }
          });
        }
        data[filter_category.name] = selected_options
      });
        //// console.log(data, "SEARCH")
        this.loading_contents = true

      await this.$http.post(fetchContentSearch, data).then( response => {
        // console.log(response)
        if(response.status == 200){
          
           if(response.data.length == 0){
            this.sortedContents = []
            this.filtered_contents = {}//this.contents
          } else {
            this.sortedContents = response.data  //[6, 7, 8] array de ids de contenidos
            let contents_found = {}
            response.data.map(id => {
              contents_found[id] = this.contents[id]
            })

            this.filtered_contents = contents_found
          } 
          this.loading_contents = false

        } else {
          this.makeToast('danger', this.$t('Error'),this.$t('error_msg'));
          this.loading_contents = false
        }
      }).catch(error => {
        this.loading_contents = false
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    selectAllContent(contents){
      let route_contents = Object.values(contents)
      // console.log("all contents TAB LP Contents", route_contents)
      this.selected_contents =  route_contents
    },
    toggleContentInList(content){
      let route_contents = [...this.selected_contents]
      const index = route_contents.findIndex(object => {
        return object.id === content.id;
      });

      if(index < 0){
        route_contents.push(content)
      } else {
        route_contents.splice(index, 1)
      }
      this.selected_contents = route_contents
    },
    unselectFilter(filters){
      this.filterOptions.map(f => {
        if(filters[0]==f.name){
          f.options.map(op => {
            if(filters[1] == op.id){
              op.selected = !op.selected
            }
          })
        }
      })
    },
    validate(){
      let errors = []
      let isValid = true
      if(!this.title){
        isValid = false
        errors.push('title')
      }
      if(!this.language){
        isValid = false
        errors.push('language')
      }
      if(this.selected_contents.length == 0){
        isValid = false
        errors.push('contents')
      }
      // console.log(errors)
      this.errors = errors
      return isValid
    },
    async save(){
      
      if(this.validate()){
        let contents_ids = this.selected_contents.map(c => c.id)
        let data = {
            token: this.$store.getters['user/getUserData'].sso,
            user_id: this.$store.getters['user/getUserData'].id,
            lang: this.language.iso_code, 
            contents: contents_ids,
            name: this.title,
            description: this.description,
            age_settings: this.age_range,
        }
        // console.log(data)
        await this.$http.post(createCatalog, data).then( response => {
          // console.log(response)
          if(response.status == 200){
            this.makeToast('success', this.$t('Success'),this.$t('resources.catalog_saved'));
            this.$router.push({ name: 'catalogs' })
          } else {
            this.makeToast('danger', this.$t('Error'),this.$t('resources.error_saving_catalog'));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        }) 
      }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    handleTemplateChange(){
      this.showSearchbar = !this.showSearchbar
    },
  }
  
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>